<template>
  <div class="bbr-programs--videos">
    <div v-if="programVideos.length" class="my-5">
      <v-card
        v-for="(grouping, groupIndex) in groupedVideos"
        :key="groupIndex"
        class="mb-8"
        outlined
      >
        <router-link
          class="clickable"
          :to="{
            name:
              grouping[0].type === 1 ? 'program.details' : 'challenge.details',
            params: { id: grouping[0].id },
            meta: { stage: 1 },
          }"
        >
          <v-card-title class="primary lighten-4">
            {{ grouping[0].name }}
          </v-card-title>

          <template v-for="(program, programIndex) in grouping">
            <v-card-text class="pa-0" :key="programIndex">
              <span class="row  align-center mx-0">
                <v-col cols="4">
                  <v-chip
                    :color="getWeekColor(program.week)"
                    text-color="white"
                    small
                  >
                    Week {{ program.program_video.week }}
                  </v-chip>
                </v-col>

                <v-col cols="4">
                  <v-chip
                    :color="getDayColor(program.day)"
                    text-color="white"
                    small
                  >
                    {{ getDay(program.day) }}
                  </v-chip>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    class="float-right"
                    color="secondary"
                    :loading="loading"
                    @click.stop="removeVideo($event, program)"
                    text
                  >
                    Remove
                  </v-btn>
                </v-col>
              </span>
            </v-card-text>
            <v-divider :key="`divider-${programIndex}`" />
          </template>
        </router-link>
      </v-card>
    </div>

    <no-list v-else details="No Videos" />

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'

import { map, sortBy, groupBy, mapValues } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProgramVideos',

  components: {
    NoList,
    ConfirmDialog,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      selectedVideo: (state) => state.videos.selectedVideo,
    }),

    ...mapGetters({
      programVideos: 'videos/getSelectedVideoProgram',
    }),

    groupedVideos() {
      let _programs = map(this.programVideos, (program) => {
        return {
          ...program,
          ...{
            day: program.program_video.day,
            week: program.program_video.week,
            program_video_id: program.program_video.id,
            program_id: program.id,
            program: program,
          },
        }
      })

      let _groupedByWeek = groupBy(_programs, 'program_id')

      return mapValues(_groupedByWeek, (program, index) => {
        return sortBy(program, ['week', 'day', 'order'])
      })
    },
  },

  methods: {
    ...mapMutations({
      removeVideoProgram: 'videos/removeVideoProgram',
    }),
    ...mapActions({
      deleteVideoProgram: 'videos/deleteVideoProgram',
    }),

    getDay(index) {
      let days = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY',
      ]

      return days[index]
    },

    getDayColor(index) {
      let colors = [
        'blue-grey',
        'blue',
        'orange',
        'pink',
        'green',
        'purple',
        'teal',
      ]

      return colors[index]
    },
    getWeekColor(index) {
      let colors = [
        'blue-grey',
        'blue',
        'orange',
        'pink',
        'green',
        'purple',
        'teal',
      ]

      return colors[index] || 'red'
    },

    async removeVideo(event, program) {
      event.preventDefault()

      const confirm = await this.$refs.confirmDelete.open(
        'Remove program',
        `Are you sure you want to remove this ${
          program.type === 1 ? 'program' : 'challenge'
        }`
      )

      if (!confirm) return
      try {
        this.loading = true

        await this.deleteVideoProgram({
          program: program.id,
          video: program.program_video_id,
        })

        this.removeVideoProgram(program)

        this.loading = false
      } catch (err) {
        // console.error(err)
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border: solid;
  border-width: 0 0 thin 0;
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.clickable {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
}
</style>

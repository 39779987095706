<template>
  <div class="bbr-videos--programs-form">
    <v-row>
      <v-col cols="12" sm="4">
        <search-programs
          v-model="form.program"
          :disabled="form.$busy || selectedVideo === null"
          :error-messages="form.$getError('video_id')"
          :hide-details="!form.$getError('video_id')"
          label="Search Programs or Challenge"
          @change="addProgram"
          return-object
          clearable
          outlined
          flat
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          v-model="form.week"
          :items="weeks"
          :error-messages="form.$getError('week')"
          label="Week"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('week')"
          outlined
          flat
          :disabled="form.program === null"
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          v-model="form.day"
          :items="days"
          :error-messages="form.$getError('day')"
          label="Day"
          item-value="id"
          item-text="name"
          :hide-details="!form.$getError('day')"
          :disabled="form.program === null"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-btn
          color="primary"
          @click="addVideoToProgram"
          block
          x-large
          :disabled="form.$busy || selectedVideo === null"
        >
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Add Program
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchPrograms from '@/components/elements/programs/SearchProgramsInput'
import Video from '@/models/Video'
import Form from '@/utils/form'

import { mapState, mapMutations } from 'vuex'
import { map, values } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'VideoProgramsForm',

  components: {
    SearchPrograms,
  },

  data() {
    return {
      video: {},
      days: [
        { id: 0, name: 'SUNDAY' },
        { id: 1, name: 'MONDAY' },
        { id: 2, name: 'TUESDAY' },
        { id: 3, name: 'WEDNESDAY' },
        { id: 4, name: 'THURSDAY' },
        { id: 5, name: 'FRIDAY' },
        { id: 6, name: 'SATURDAY' },
      ],

      icons: {
        add: mdiPlus,
      },

      form: new Form({
        day: null,
        week: null,
        video_id: null,
        program: null,
      }),
    }
  },

  created() {
    this.video = this.selectedVideo
    this.form.video_id = this.selectedVideo.id
  },

  computed: {
    ...mapState({
      selectedVideo: (state) => state.videos.selectedVideo,
    }),

    weeks() {
      if (this.form?.program?.week_count) {
        return map([...Array(this.form.program.week_count).keys()], (week) => {
          let _week = week + 1

          return {
            id: _week,
            name: _week,
          }
        })
      } else {
        return {}
      }
    },
  },

  methods: {
    ...mapMutations({
      setSelectedVideoProgram: 'videos/setSelectedVideoProgram',
    }),

    addProgram(program) {
      this.form.program = program || null
    },

    async addVideoToProgram() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let video = new Video({
          week: this.form.week,
          day: this.form.day,
          video_id: this.selectedVideo.id,
        }).for(this.form.program)
        let res = await video.save()
        let newProgram = this.form.program
        let resVals = values(res).map((program) => {
          return program.program_video.id
        })
        newProgram.program_video = {
          week: this.form.week,
          day: this.form.day,
          order: values(res).length,
          id: Math.max.apply(null, resVals),
        }
        this.setSelectedVideoProgram([
          ...this.selectedVideo.programs,
          newProgram,
        ])
        this.form.$reset()
        this.form.$busy = false
      } catch ({ response }) {
        this.form.$timeout(() => {
          this.form.$busy = false

          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
    },
  },

  watch: {
    selectedVideo(video) {
      this.form.video = video
    },
    errors(errors) {
      this.form.$setErrors(errors)
    },
  },
}
</script>

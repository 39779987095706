<template>
  <v-autocomplete
    v-model="programs"
    v-bind="$attrs"
    :items="selectedPrograms"
    :label="label"
    :placeholder="placeholder"
    :search-input.sync="search"
    :error-messages="errorMessages"
    item-text="name"
    item-value="id"
    hide-details="auto"
  >
    <template v-if="isMultiple" v-slot:selection="{ item, index }">
      <div v-if="isForFilter">
        <v-chip
          v-if="index <= 0"
          v-bind="item.attrs"
          @click:close="removeProgram(item.index)"
          close
        >
          <span>{{ item.name }}</span>
        </v-chip>

        <span v-if="index === 1" class="grey--text caption ml-2">
          (+{{ value.length - 1 }} others)
        </span>
      </div>

      <v-chip
        v-else
        class="mt-3 text-capitalize"
        v-bind="item.attrs"
        @click:close="removeProgram(item.index)"
        close
      >
        {{ item.name }}
      </v-chip>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="d-flex align-center">
          <span> {{ item.name }} </span>
          <span class="caption ml-auto primary--text">
            {{ item.programType }}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle class="text-no-wrap" style="width:20px">
          {{ item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Program from '@/models/Program'
import { filter, debounce, uniqBy } from 'lodash'

export default {
  name: 'SearchProgramsInput',

  props: {
    value: {
      type: [Array, Object],
    },

    type: {
      type: [String, Number],
    },

    label: {
      type: String,
      default: 'Programs',
    },

    placeholder: {
      type: String,
      default: 'Search for Program',
    },

    errorMessages: {
      type: [Object, Array],
    },
  },

  data() {
    return {
      search: null,
      selectedProgram: null,

      programs: [],
      searchedPrograms: [],
    }
  },

  computed: {
    selectedPrograms() {
      return Array.isArray(this.programs)
        ? uniqBy([...this.programs, ...this.searchedPrograms], 'id')
        : uniqBy(
            filter([this.programs, ...this.searchedPrograms], (prog) => prog),
            'id'
          )
    },

    hasSearch() {
      return !!this.search
    },

    isMultiple() {
      return 'multiple' in this.$attrs
    },

    isForFilter() {
      return 'is-for-filter' in this.$attrs
    },
  },

  created() {
    this.programs = this.value
  },

  methods: {
    searchForPrograms: debounce(function() {
      this.fetchPrograms().then(({ data }) => {
        this.searchedPrograms = data
      })
    }, 800),

    async fetchPrograms() {
      let query = await Program.where('search', this.search)
        .include('variants')
        .where('completed', true)
        .params({ limit: 100 })

      return query.get()
    },

    removeProgram(index) {
      this.programs.splice(index, 1)
    },
  },

  watch: {
    search(key) {
      if (key) {
        this.searchForPrograms()
      }
    },

    programs(values) {
      this.$emit('input', values)
      this.$emit('change', values)
    },

    value(values) {
      this.programs = values
    },
  },
}
</script>
